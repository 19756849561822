// /* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Title } from "../../components";
import { LightButton, PrimaryBtn, SecondaryBtn } from "../../components/button";
import Web3 from "web3";
import { useNavigate } from "react-router-dom";

const Overview = ({ account, contract, loadWeb3, tokenContract }) => {
  const [totalStaked, setTotalStaked] = useState(0);
  const [totalClaimed, setTotalClaimed] = useState(0);
  const [userStakedBalance, setUserStakedBalance] = useState(0);
  const [userUnClaimedBalance, setUserUnClaimedBalance] = useState(0);

  useEffect(() => {
    const getStakeData = async () => {
      if (contract && account) {
        console.log(contract);
        try {
          const amountStaked = await contract.methods
            .totalStakedBalance()
            .call();
          console.log("amountStaked", amountStaked);
          const convertedStakedValue = Web3.utils.fromWei(
            amountStaked,
            "ether"
          );

          setTotalStaked(Number(convertedStakedValue).toFixed(2));

          let totalClaimed = await contract.methods
            .totalClaimedBalance()
            .call();
          console.log("totalClaimed", totalClaimed);
          totalClaimed = Web3.utils.fromWei(totalClaimed, "ether");

          setTotalClaimed(Number(totalClaimed).toFixed(2));

          let _totalUnclaimedBalance = await contract.methods
            .getTotalUnclaimedRewards()
            .call({ from: account });

          _totalUnclaimedBalance = Web3.utils.fromWei(
            _totalUnclaimedBalance,
            "ether"
          );
          setUserUnClaimedBalance(Number(_totalUnclaimedBalance).toFixed(2));
          console.log(_totalUnclaimedBalance);
          const userDetails = await contract.methods
            .getUserDetails()
            .call({ from: account });
          let _stakeBalance = Web3.utils.fromWei(userDetails[1], "ether");
          setUserStakedBalance(Number(_stakeBalance).toFixed(2));
          // setUserUnClaimedBalance(Web3.utils.fromWei(userDetails['totalClaimedRewards']));
          // console.log("amount", amountClaimed);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getStakeData();
  }, [account, contract, tokenContract]);

  return (
    <div>
      <div className="bg-gradient-primary py-10 px-4 rounded-xl">
        <Title desc="Stake $MIKA in one of our four core pools to start earning rewards." />
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:mx-40 mt-6 ">
          <OverviewContent
            price={`${userStakedBalance}`}
            account={account}
            loadWeb3={loadWeb3}
          />
          <OverviewContent
            title="YOUR UNCLAIMED REWARDS"
            price={`${userUnClaimedBalance}`}
            btn="secondary"
            btnText="Claim"
            disable={userUnClaimedBalance > 0 ? false : true}
            account={account}
            loadWeb3={loadWeb3}
            // hanlder={() =>}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 mt-8 gap-6 font-sans uppercase text-white">
        <div className="p-8 py-14 bg-glass text-center  grid items-center justify-center rounded-xl">
          <h6 className="font-semibold md:text-3xl mb-4 ">
            Total Amount Staked
          </h6>
          <div className="shadow-box pt-1 pb-2 text-3xl font-bold px-20 rounded-full ">
            {totalStaked} MIKA
          </div>
        </div>
        <div className="p-8 py-14 bg-glass text-center grid items-center justify-center rounded-xl">
          <h6 className="font-semibold md:text-3xl mb-4 ">
            Total Amount Claimed
          </h6>
          <div className="shadow-box pt-1 pb-2 text-3xl font-bold px-20 rounded-full">
            {totalClaimed} MIKA
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;

const OverviewContent = ({
  title = "YOUR STAKED BALANCE",
  price = "",
  btn = "primary",
  btnText = "Stake",
  disable = false,
  account,
  loadWeb3,
}) => {
  const navigate = useNavigate();

  return (
    <div className=" text-center shadow-box py-8 rounded-xl font-sans">
      <h6 className="font-semibold text-white text-2xl md:text-3xl font-sans md:px-20">
        {title}
      </h6>
      <p className="font-semibold text-3xl sm:text-4xl my-6 font-sans">
        {price}
      </p>

      {btn === "primary" ? (
        <>
          {account ? (
            <a href="#stack">
              <PrimaryBtn>{btnText}</PrimaryBtn>
            </a>
          ) : (
            <PrimaryBtn onClick={loadWeb3}>Connect Wallet</PrimaryBtn>
          )}
        </>
      ) : account ? (
        <PrimaryBtn disable={disable} onClick={() => navigate("/rewards")}>
          {btnText}
        </PrimaryBtn>
      ) : (
        <PrimaryBtn onClick={loadWeb3}>Connect Wallet</PrimaryBtn>
      )}
    </div>
  );
};
