import StackInput from "./StackInput";
import Slider, { SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
const { Handle } = Slider;

const Locked = ({
  handler,
  value = 0,
  setValue,
  balance,
  stackValue,
  inputHandler,
  approved,
  poolData,
  setStackValue,
  raritesToApply,
}) => {
  const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    setValue(value);
    // console.log(value - 1);
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value}`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };
  // console.log(poolData[count]?.days);
  const count = value - 1;
  const days = poolData[count]?.days;
  const apr = poolData[count]?.apr;
  const stakeId = poolData[count]?.id;
  const multiplier = poolData[count]?.multiplier;
  // console.log(stakeId);
  console.log(poolData);
  return (
    <>
      <div className="mb-4 mt-6">
        <div className="flex justify-between items-center mb-4 md:mb-6">
          <p>
            Lock For: <span className="font-medium">{days} Days</span>
          </p>
        </div>
        <Slider
          min={1}
          max={poolData.length}
          defaultValue={0}
          handle={handle}
        />
      </div>
      <p className="mt-4 mb-4 font-semibold text-lg">
        {" "}
        Balance: {Number(balance).toFixed(2)} MIKA
      </p>
      <StackInput
        stackValue={stackValue}
        inputHandler={inputHandler}
        setStackValue={setStackValue}
        balance={balance}
      />
      <p className="text-left text-base my-4">
        DAILY REWARD: {apr ? (Number(apr) / 365).toFixed(2) : apr}%
      </p>
      <div className="p-2 md:py-4  px-4 bg-red text-red rounded-xl text-xs">
        <p className="md:mx-10 text-center">
          MIKA strives to offer its holders the heighest levels of DeFi
          project-on-chain contract security, however you must be aware that
          there are always risks associated with staking contracts and you
          assume all associated responsibility
        </p>
      </div>
      <div className="mt-6 md:mt-10 primary-btn">
        <button
          className="bg-gradient-primary font-bold text-xl py-2 px-8 w-full rounded-full"
          onClick={() => handler(stakeId)}
        >
          {approved ? "Stake" : "Approve"}
        </button>
      </div>
    </>
  );
};

export default Locked;
