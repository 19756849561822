/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { useEffect, useState } from "react";
import "rc-slider/assets/index.css";
import InformationModal from "../../components/modals/InformationModal";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import Web3 from "web3";
import { contractAddress } from "../../contracts/primary";
import { useWeb3React } from "@web3-react/core";
import Locked from "./components/Locked";
import { useNavigate } from "react-router-dom";
const Stake = ({ contract, tokenContract }) => {
  const navigate = useNavigate();
  const { account, library } = useWeb3React();
  const [value, setValue] = useState(0);
  const [balance, setBalance] = useState(0);
  const [approved, setApproved] = useState(false);
  const [transactionRejected, setTransactionRejected] = useState(false);
  const [transactionFailed, setTransactionFailed] = useState(false);
  const [confirmTransaction, setConfirmTransaction] = useState(false);
  const [transactionInProgress, setransactionInProgress] = useState(false);
  const [confirmTransaction2, setConfirmTransaction2] = useState(false);
  const [transactionInProgress2, setransactionInProgress2] = useState(false);
  const [transactionSuccess, setransactionSuccess] = useState(false);
  const [lessAmount, setLessAmount] = useState(false);
  const [stackValue, setStackValue] = useState(0);
  const [raritesToApply, setRaritesToApply] = useState(0);
  const [allPoolsAPY, setAllPoolAPY] = useState(0);
  const [allPoolsDetails, setAllPoolsDetails] = useState([]);
  const [aprs, setAprs] = useState([]);
  useEffect(() => {
    const getDeatilsStaking = async () => {
      const allPoolsDetails = await contract.methods.getAllPoolDetails().call();
      setAllPoolsDetails(allPoolsDetails);
      console.log(allPoolsDetails);
    };
    if (contract) {
      getDeatilsStaking();
    }
  }, [contract, account, library]);

  const stakeLockedDetails = [
    {
      id: allPoolsDetails.length ? allPoolsDetails[0][0] : "",
      days: allPoolsDetails.length ? allPoolsDetails[0][1] : "",
      apr: allPoolsDetails.length ? allPoolsDetails[0][3] : "",
      poolWeight: "30",
      multiplier: allPoolsDetails.length
        ? allPoolsDetails[0]["multiplier"]
        : "",
    },
    {
      id: allPoolsDetails.length ? allPoolsDetails[1]["id"] : "",
      days: allPoolsDetails.length ? allPoolsDetails[1]["duration"] : "",
      apr: allPoolsDetails.length ? allPoolsDetails[1]["APY"] : 0,
      poolWeight: "40",
      multiplier: allPoolsDetails.length
        ? allPoolsDetails[1]["multiplier"]
        : "",
    },
    {
      id: allPoolsDetails.length ? allPoolsDetails[2]["id"] : "",
      days: allPoolsDetails.length ? allPoolsDetails[2]["duration"] : "",
      apr: allPoolsDetails.length ? allPoolsDetails[2]["APY"] : 0,
      poolWeight: "50",
      multiplier: allPoolsDetails.length
        ? allPoolsDetails[2]["multiplier"]
        : "",
    },
    {
      id: allPoolsDetails.length ? allPoolsDetails[3]["id"] : "",
      days: allPoolsDetails.length ? allPoolsDetails[3]["duration"] : "",
      apr: allPoolsDetails.length ? allPoolsDetails[3]["APY"] : 0,
      poolWeight: "50",
      multiplier: allPoolsDetails.length
        ? allPoolsDetails[3]["multiplier"]
        : "",
    },
  ];

  // console.log(stakeDetails);
  // APPROVE FUNCTION👇
  const approveHandler = async () => {
    // console.log("call", contract2, contractAddress);
    if (tokenContract) {
      setConfirmTransaction(true);
      //   window.localStorage.setItem("approving", "true");

      tokenContract.methods
        .approve(contractAddress, "1000000000000000000000000")
        .send({ from: account })
        .on("transactionHash", async function (response) {
          setConfirmTransaction(false);
          // setListApprove(true);
          setransactionInProgress(true);
        })
        .on("confirmation", function () {})
        .on("receipt", function () {
          // setListNftApproved(true);
          setConfirmTransaction(false);
          setransactionInProgress(false);
          // setListApprove(false);
          // setApprove(true);
          // setListNftApproved(false);
          setApproved(true);
          // setransactionSuccess(true);
          //   window.localStorage.removeItem("approving");
        })
        .on("error", function (error, receipt) {
          //   window.localStorage.removeItem("approving");
          if (error.code === 4001) {
            setTransactionRejected(true);
            setConfirmTransaction(false);
            // setListApprove(false);
          } else {
            setTransactionFailed(true);
            setConfirmTransaction(false);
            // setListApprove(false);
          }
        });
    }
  };
  // APPROVE FUNCTION👆

  //STAKE FUNCTION👇
  const stakingHandler = async (stakeId) => {
    // console.log("call", contract2, contractAddress);
    console.log(stackValue);
    if (Number(stackValue) > 1) {
      if (contract) {
        setConfirmTransaction2(true);
        const value = Web3.utils.toWei(stackValue, "ether");
        contract.methods
          .stake(stakeId, value)
          .send({ from: account })
          .on("transactionHash", async function (response) {
            setConfirmTransaction2(false);
            setransactionInProgress2(true);
          })
          .on("confirmation", function () {})
          .on("receipt", function () {
            // setListNftApproved(true);
            setConfirmTransaction2(false);

            setransactionInProgress2(false);
            // setApproved(true);
            setransactionSuccess(true);
            // window.localStorage.removeItem("approving");
            // setransactionSuccess();
            setTimeout(function () {
              navigate("/rewards");
            }, 300);
          })
          .on("error", function (error, receipt) {
            setConfirmTransaction(false);
            setransactionInProgress2(false);
            if (error.code === 4001) {
              setTransactionRejected(true);
              // setListApprove(false);
            } else {
              setTransactionFailed(true);
              // setListApprove(false);
            }
          });
      }
    } else {
      setLessAmount(true);
    }
  };

  // STAKING BUTTON HANDLER 👇
  const stakingBtnHandler = (stakeId) => {
    if (approved) {
      stakingHandler(stakeId);
    } else {
      approveHandler();
    }
  };
  // STAKING BUTTON HANDLER 👆

  // GET BALANCE AND CHECK APPROVAL 👇
  useEffect(() => {
    const getDetail = async () => {
      try {
        if (tokenContract) {
          const data = await tokenContract.methods.balanceOf(account).call();
          //   setDetails(data[2]);
          const convertedData = Web3.utils.fromWei(data, "ether");
          // console.log(convertedData);
          setBalance(convertedData);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDetail();
    const checkApproval = async () => {
      try {
        if (tokenContract) {
          const data = await tokenContract.methods
            .allowance(account, contractAddress)
            .call();
          if (Number(data) > 0) {
            setApproved(true);
          } else {
            setApproved(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    checkApproval();
  }, [tokenContract, contract, account, library]);
  // GET BALANCE AND CHECK APPROVAL 👆

  const inputHandler = (e) => {
    setStackValue(e.target.value);
  };

  return (
    <div>
      <div className="container">
        <div className=" max-w-xl w-full mx-auto bg-glass2 p-6 rounded-xl shadow-xl hover:shadow-2xl transition-all duration-300 ">
          <h1 className=" text-left font-bold text-xl md:text-4xl mt-0 font-sans uppercase">
            Stake MIKA
          </h1>

          <Locked
            handler={stakingBtnHandler}
            value={value}
            setValue={setValue}
            balance={balance}
            stackValue={stackValue}
            inputHandler={inputHandler}
            approved={approved}
            poolData={stakeLockedDetails}
            setStackValue={setStackValue}
            raritesToApply={raritesToApply}
            allPoolsDetails={allPoolsDetails}
          />
        </div>
      </div>
      <InformationModal
        open={transactionRejected}
        setOpen={setTransactionRejected}
        title="Error"
        desc="Transaction Rejected!"
      />
      <InformationModal
        open={transactionFailed}
        setOpen={setTransactionFailed}
        title="Error"
        desc="Transaction Failed!"
      />
      <InformationModal
        open={transactionSuccess}
        setOpen={setransactionSuccess}
        title="Success"
        desc="Transaction Completed Successfully!"
      />
      <InformationModal
        open={lessAmount}
        setOpen={setLessAmount}
        title="Oops"
        desc="Amount Should not be less then 1"
      />

      <ConfirmationModal
        open={confirmTransaction}
        title="Confirm Transaction"
        desc="Please confirm transaction!"
      />
      <ConfirmationModal
        open={transactionInProgress}
        title="Transaction In Progress"
        desc="Please wait to get confirmation of the transaction from blockchain!"
      />
      <ConfirmationModal
        open={confirmTransaction2}
        title="Confirm Transaction"
        desc="Please confirm transaction!"
      />
      <ConfirmationModal
        open={transactionInProgress2}
        title="Transaction In Progress"
        desc="Please wait to get confirmation of the transaction from blockchain!"
      />
    </div>
  );
};

export default Stake;
