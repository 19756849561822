const StackInput = ({
  stackValue,
  inputHandler,
  balance = 0,
  setStackValue,
}) => (
  <form action="" onSubmit={(e) => e.preventDefault()}>
    <div className="stack-input-wrapper">
      <label>Amount</label>
      <div className="stack-input bg-ddd">
        <input
          type="text"
          placeholder="Enter  PYR to be staked"
          value={stackValue}
          onChange={inputHandler}
        />
        <button
          className="input-btn bg-gradient-primary"
          onClick={() => setStackValue(balance)}
        >
          Max
        </button>
      </div>
    </div>
  </form>
);

export default StackInput;
