import { useEffect, useRef } from "react";
import "./style.css";
import { PrimaryBtn } from "../button";
const Index = ({ open, setOpen }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handler = (e) => {
      if (!modalRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const logoutHandler = () => {
    window.location.href = `https://metamask.app.link/dapp/${window.location.host}`;
    setTimeout(() => {
      setOpen(false);
    }, 5000);
  };
  return (
    <div className={`modal logout ${open ? "active" : ""}`}>
      <div className="modal-content-wrapper">
        <div className="container">
          <div className="modal-wrapper" ref={modalRef}>
            <h1 className="text-primary font-semibold text-3xl mt-14">
              Please Install Metamask
            </h1>
            <p className=" text-base max-w-xs mx-auto mt-4">
              Click the button below to install Metamask to use this Dapp.
            </p>
            <div className="mt-10">
              <PrimaryBtn roundedfull={true} onClick={logoutHandler}>
                Install Metamask
              </PrimaryBtn>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
