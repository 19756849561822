const Index = ({ title = "Overview", desc }) => {
  return (
    <div className="text-center font-sans">
      <h1 className="  text-4xl md:text-6xl uppercase font-semibold ">
        {title}
      </h1>
      {desc && (
        <p className=" font-normal  text-sm md:text-xl mt-8 md:mx-20  xl:mx-60  uppercase">
          {desc}
        </p>
      )}
    </div>
  );
};

export default Index;
