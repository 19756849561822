/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Title } from "../../components";
import { MiniButton } from "../../components/button";
import Slogo from "../../assets/images/shkooby_icon1.png";
import { StackDetailModal, StackModal } from "../../components";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
// import axios from "axios"
const Pools = ({ poolIds, contract, tokenContract, loadWeb3 }) => {
  const [details, setDetails] = useState(false);
  const [flexi, setFlexi] = useState(false);
  const [detailsData, setDetailsData] = useState({});
  const [stack, setStack] = useState(false);
  const [stackId, setStackId] = useState(0);
  const [poolData, setPoolData] = useState([]);
  const { account, library } = useWeb3React();
  const [pools, setPools] = useState([]);
  // console.log(poolData);
  // console.log(poolIds);
  useEffect(() => {
    try {
      if (contract) {
        if (poolIds.length > poolData.length) {
          poolIds.forEach(async (element) => {
            const data = await contract.methods
              .getStakePoolDetails(element)
              .call();

            setPoolData((prev) => [...prev, data]);
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, contract, poolIds]);
  useEffect(() => {
    const getDeatilsStaking = async () => {
      const allPoolsDetails = await contract.methods.getAllPoolDetails().call();
      console.log(allPoolsDetails[0]);
      // setAllPoolsDetails(allPoolsDetails[0]);
      // setAprs(allPoolsDetails[1]);
      // console.log(allPoolsDetails);

      // const allApys = await contract.methods.getDynamicAPYOfAllPools().call();
      // setAprs(allApys);
    };
    if (contract) {
      getDeatilsStaking();
    }
  }, [contract, account, library]);

  // console.log(poolData);
  const poolsData = [
    {
      img: Slogo,
      title: "7 days lock",
      btn: [
        {
          type: "outline",
          content: "Details",
        },
        {
          type: "filled",
          content: "Stake",
        },
      ],
    },
    {
      img: Slogo,
      title: "30 days lock",
      btn: [
        {
          type: "outline",
          content: "Details",
        },
        {
          type: "filled",
          content: "Stake",
        },
      ],
    },
    {
      img: Slogo,
      title: "90 days lock ",
      btn: [
        {
          type: "outline",
          content: "Details",
        },
        {
          type: "filled",
          content: "Stake",
        },
      ],
    },
    {
      img: Slogo,
      title: "180 days lock ",
      btn: [
        {
          type: "outline",
          content: "Details",
        },
        {
          type: "filled",
          content: "Stake",
        },
      ],
    },
  ];

  return (
    <div className="py-20" id="stack">
      <Title title="Pools" />
      <div className="mt-20 overflow-x-auto">
        <h6 className="font-bold text-3xl uppercase font-sans">Core Pools</h6>
        <div className="mt-8 ">
          {poolsData.length ? (
            poolsData.map((val, index) => {
              return (
                <div
                  className="bg-glass2 border border-white border-opacity-20 rounded-3xl py-4 px-6 flex-col sm:flex-row flex sm:justify-between items-center mb-6"
                  key={index}
                >
                  <div className="flex items-center mb-5 sm:mb-0">
                    <p className="font-medium text-xl ml-3 font-sans uppercase ">
                      {val.title}
                    </p>
                  </div>
                  <div className="flex items-center ">
                    <Link
                      to={`stake`}
                      onClick={(e) => !account && e.preventDefault()}
                    >
                      <button
                        className={`${
                          account
                            ? "shadow-box"
                            : "bg-gray-500 pointer-events-none cursor-not-allowed "
                        }  rounded-lg py-2 px-8`}
                      >
                        Stake
                      </button>
                    </Link>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="bg-white bg-opacity-10 shadow rounded-md p-4 w-full mx-auto mb-4 py-20">
              <button
                onClick={loadWeb3}
                className="bg-gray-600 py-3 px-10  rounded-md font-bold mx-auto block "
              >
                Connect Wallet
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pools;
