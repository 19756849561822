/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { InformationModal, Title } from "../../components";
import ConfirmationModal from "../../components/modals/ConfirmationModal";

import { MiniButton } from "../../components/button";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Web3 from "web3";

dayjs.extend(utc);

const Reward = ({
  stakesDetails,
  contract,
  contract2,
  contractAddress,
  contractAddress2,

  account,
}) => {
  const [transactionRejected, setTransactionRejected] = useState(false);
  const [transactionFailed, setTransactionFailed] = useState(false);
  const [confirmTransaction, setConfirmTransaction] = useState(false);
  const [transactionInProgress, setransactionInProgress] = useState(false);
  const [confirmTransaction2, setConfirmTransaction2] = useState(false);
  const [transactionInProgress2, setransactionInProgress2] = useState(false);
  const [transactionSuccess, setransactionSuccess] = useState(false);
  const [lessAmount, setLessAmount] = useState(false);
  const [totalClaimedBalance, setTotalClaimedBalance] = useState(0);
  const [unClaimedBalance, setUnClaimedBalance] = useState([]);
  useEffect(() => {
    const getData = async () => {
      if (contract) {
        const totalClaimedBalance = await contract.methods
          .getUserDetails()
          .call({ from: account });
        console.log(totalClaimedBalance);
        setTotalClaimedBalance(
          Web3.utils.fromWei(totalClaimedBalance[2], "ether")
        );
        setUnClaimedBalance([]);
        if (stakesDetails.length > 0) {
          stakesDetails.forEach(async (val) => {
            const unclaimedStakes = await contract.methods
              .getUnclaimedRewards(val["id"])
              .call({ from: account });
            //  console.log(Web3.utils.fromWei(unclaimedStakes, 'ether'))
            const balance = Web3.utils.fromWei(unclaimedStakes, "ether");
            setUnClaimedBalance((prev) => [
              ...prev,
              { id: val["id"], balance: balance },
            ]);
          });
        }
      }
    };
    getData();
    // getUnclaimedBalance(1)
  }, [stakesDetails.length, contract]);

  console.log(unClaimedBalance);

  const claim = (stakeId) => {
    if (contract) {
      setConfirmTransaction(true);
      //   window.localStorage.setItem("approving", "true");

      contract.methods
        .claimRewards(stakeId)
        .send({ from: account })
        .on("transactionHash", async function (response) {
          setConfirmTransaction(false);
          // setListApprove(true);
          setransactionInProgress(true);
        })
        .on("confirmation", function () {})
        .on("receipt", function () {
          // setListNftApproved(true);
          setConfirmTransaction(false);
          setransactionInProgress(false);
          setransactionSuccess(true);
        })
        .on("error", function (error, receipt) {
          //   window.localStorage.removeItem("approving");
          setConfirmTransaction(false);
          setransactionInProgress(false);
          if (error.code === 4001) {
            setTransactionRejected(true);
            // setListApprove(false);
          } else {
            setTransactionFailed(true);

            // setListApprove(false);
          }
        });
    }
  };
  const unStake = (stakeId) => {
    if (contract) {
      setConfirmTransaction2(true);
      contract.methods
        .unstake(stakeId)
        .send({ from: account })
        .on("transactionHash", async function (response) {
          setConfirmTransaction2(false);
          // setListApprove(true);
          setransactionInProgress2(true);
        })
        .on("confirmation", function () {})
        .on("receipt", function () {
          setransactionInProgress2(false);
          setConfirmTransaction2(false);

          setransactionSuccess(true);
        })
        .on("error", function (error, receipt) {
          //   window.localStorage.removeItem("approving");
          setransactionInProgress2(false);
          setConfirmTransaction2(false);
          if (error.code === 4001) {
            setTransactionRejected(true);
            // setListApprove(false);
          } else {
            setTransactionFailed(true);
            // setListApprove(false);
          }
        });
    }
  };
  return (
    <div>
      <div className="bg-gradient-primary py-10 rounded-xl">
        <Title title="Rewards" desc="" />
        <p className="font-semibold  text-base mt-2  md:mx-20 text-center  xl:mx-72 ">
          Stake $MIKA in any of our pools to start earning rewards with variable
          locking pools explained below:
        </p>
        {/* <p className="font-normal  text-base text-center mt-2  md:mx-20  xl:mx-72 ">
          MIKA Flexi Pool allows you to redeem your staked assets at any time.
        </p> */}
        <p className="font-normal  text-base text-center mt-2  md:mx-20  xl:mx-72 ">
          MIKA Locked Pool allows you to staking your assets for a specific time
          frame. Compared to Flexible Staking, Locked Staking requires you to
          lock your assets for the displayed duration. You can earn daily
          rewards in MIKA over your staking period.
        </p>
        <div className=" shadow-box mx-14 py-10 px-6 mt-10  rounded-lg font-sans">
          <div className=" text-center mx-auto">
            <p className=" font-semibold text-xl md:text-4xl text-primary uppercase">
              Claimed Rewards
            </p>
            <p className="font-bold text-xl md:text-4xl mt-4">
              {totalClaimedBalance} MIKA
            </p>
          </div>
        </div>
        {/* <p className=" text-base md:text-xl font-medium p-4 mt-6 pb-0 text-center">
          Next rewards released in: 2 hours 54 minutes
        </p> */}
      </div>
      <div className="mt-14">
        <Title title="Stakes & Rewards" />
      </div>
      <div className="overflow-x-auto ">
        <div className="mt-20" style={{ width: 1288 }}>
          <div className="grid grid-cols-2 gap-20 px-10 font-sans uppercase text-xl">
            <div className="flex items-center justify-between">
              <p className=" font-medium ">Core Pools</p>
              <p className="font-medium ">MIKA Staked</p>
            </div>
            <div className="flex items-center justify-between">
              <p className=" font-medium ">Total Rewards</p>
              <p className=" font-medium  mr-40">Claimable Rewards</p>
              {/* <p className=" font-medium  ">Unlock Time</p> */}
              <p className=" font-medium "></p>
            </div>
          </div>
          <div className="mt-10">
            {stakesDetails &&
              stakesDetails
                .filter((val) => Number(val["stakeBalance"]) > 0)
                .map((v, i) => {
                  console.log("value", v[0][0]);
                  const date = dayjs
                    .utc(Number(v["createdTime"]) * 1000)
                    .format()
                    .slice(0, 10);
                  const date3 = dayjs(Number(v["unlockTime"]) * 1000).format(
                    "YYYY-MM-DD"
                  );
                  console.log(v["lastClaimedTime"]);
                  const date2 =
                    Number(v["lastClaimedTime"]) === 0
                      ? "Never"
                      : dayjs
                          .utc(Number(v["lastClaimedTime"]) * 1000)
                          .format()
                          .slice(0, 10);
                  const stackBalance = Web3.utils.fromWei(
                    v["stakeBalance"],
                    "ether"
                  );
                  // const claimableBalance = 0
                  const totalRewards = Web3.utils.fromWei(
                    v["totalClaimedRewards"],
                    "ether"
                  );
                  const isAbletoUnstake =
                    Number(v["unlockTime"]) * 1000 - Date.now();
                  console.log(isAbletoUnstake);
                  let claimableBalance = 0;
                  if (unClaimedBalance.length > 0) {
                    // eslint-disable-next-line eqeqeq
                    claimableBalance = unClaimedBalance.filter(
                      (val) => val.id == v["id"]
                    )[0]?.balance;
                  }
                  console.log(claimableBalance);

                  return (
                    <div
                      className=" bg-glass2 rounded-md py-6 px-6 grid grid-cols-2 items-center mb-6 gap-20"
                      key={i}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex items-center">
                          <p className="ml-3 font-semibold  text-lg">
                            <p>{`Locked Pool ${v["lockDuration"]} Days`}</p>
                            <p className="text-xs block text-white mt-1">
                              Created Time : {date}
                            </p>
                            <p className="text-xs block text-white">
                              Unlock Time : {date3}
                            </p>
                          </p>
                        </div>
                        <p className="ml-3 font-semibold  text-lg">
                        <p>{Number(stackBalance).toFixed(2)} MIKA</p>
                        </p>
                      </div>
                      <div className=" flex items-center  justify-between">
                        <p className="text-lg font-semibold"> {Number(totalRewards).toFixed(2)}</p>
                        <p className="text-lg font-semibold">
                          {" "}
                          <p>{Number(claimableBalance).toFixed(2)} MIKA</p>{" "}
                          <p className="text-xs text-white">
                            Last Claimed: {date2}
                          </p>
                        </p>
                        <div className="grid grid-flow-col justify-end">
                          <button
                            className={`${
                              claimableBalance > 0
                                ? " bg-gradient-primary"
                                : " bg-gray-500 pointer-events-none  "
                            }  py-2 px-4 rounded-md`}
                            onClick={() => claim(v["id"])}
                          >
                            Claim
                          </button>
                          <button
                            className={`${
                              isAbletoUnstake < 0
                                ? "bg-gradient-primary"
                                : " bg-gray-500 pointer-events-none  "
                            }  ml-2 py-2 px-4 rounded-md`}
                            onClick={() => unStake(v["id"])}
                          >
                            UnStake
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
      <InformationModal
        open={transactionRejected}
        setOpen={setTransactionRejected}
        title="Error"
        desc="Transaction Rejected!"
      />
      <InformationModal
        open={transactionFailed}
        setOpen={setTransactionFailed}
        title="Error"
        desc="Transaction Failed!"
      />
      <InformationModal
        open={transactionSuccess}
        setOpen={setransactionSuccess}
        title="Success"
        desc="Transaction Completed Successfully!"
      />
      <InformationModal
        open={lessAmount}
        setOpen={setLessAmount}
        title="Oops"
        desc="Amount Should not be less then 1"
      />

      <ConfirmationModal
        open={confirmTransaction}
        title="Confirm Transaction"
        desc="Please confirm transaction!"
      />
      <ConfirmationModal
        open={transactionInProgress}
        title="Transaction In Progress"
        desc="Please wait to get confirmation of the transaction from blockchain!"
      />
      <ConfirmationModal
        open={confirmTransaction2}
        title="Confirm Transaction"
        desc="Please confirm transaction!"
      />
      <ConfirmationModal
        open={transactionInProgress2}
        title="Transaction In Progress"
        desc="Please wait to get confirmation of the transaction from blockchain!"
      />
    </div>
  );
};

export default Reward;
