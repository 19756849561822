/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from "./components";
import Home from "./pages/Home";
import Reward from "./pages/Rewards";
import LeaderBoard from "./pages/LeaderBoard";
import Swap from "./pages/Swap";
import Modal from "./components/modals";
import WalletModal from "./components/walletModal";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { injected, walletconnect, CoinbaseWallet } from "./wallet/connector";
import {
  LogoutModal,
  StackModal,
  StackDetailModal,
  InformationModal,
} from "./components";
import Web3 from "web3";
import { contractAbi, contractAddress } from "./contracts/primary";
import {
  contractAbi2 as tokenAbi,
  contractAddress2 as tokenAddress,
} from "./contracts/token";
import "./App.scss";
import Details from "./pages/Details";
import Stake from "./pages/Stake/Stake";
import { CONNECTABLE_CHAINID } from "./utils";
import toast, { Toaster } from "react-hot-toast";
// toast.configure();
function App() {
  const context = useWeb3React();
  const { chainId, account, activate, deactivate, error, library } = context;
  // const [chainId, setChainId] = useState(null);
  // const [account, setAccount] = useState(null);
  const [contract, setContract] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);

  const [totalSupply, setTotalSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [price, setPrice] = useState(0);
  const [displayPrice, setDisplayPrice] = useState(0);
  const [accessAccountDenied, setAccessAccountDenied] = useState(false);
  const [installEthereum, setInstallEthereum] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [stackOpen, setStackOpen] = useState(false);
  const [stackDetailOpen, setStackDetailOpen] = useState(false);
  const [poolIds, setPoolIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [mainnetError, setMainnetError] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const connectWallet = async (connectTo, name) => {
    console.log("calling connect");
    try {
      if (name === "Metamask") {
        if (window.ethereum) {
          await activate(connectTo);
          window.localStorage.setItem("WEB3_REACT_CURRENT_PROVIDER", name);
        } else {
          window.location.href = `https://metamask.app.link/dapp/${window.location.host}`;
        }
      } else {
        await activate(connectTo);
        window.localStorage.setItem("WEB3_REACT_CURRENT_PROVIDER", name);
      }
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (error instanceof UnsupportedChainIdError) {
      toast.error("PLease connect to mainnet");
      // console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const setContractsAndBlockchainData = async () => {
    if (library) {
      try {
        const _contract = await new library.eth.Contract(
          contractAbi,
          contractAddress
        );
        setContract(_contract);
        const _tokenContract = await new library.eth.Contract(
          tokenAbi,
          tokenAddress
        );
        setTokenContract(_tokenContract);
      } catch (error) {
        console.log("CONTRACT ERROR", error);
      }
    }
  };
  useEffect(() => {
    setContractsAndBlockchainData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, library]);

  useEffect(() => {
    const localProvider = window.localStorage.getItem(
      "WEB3_REACT_CURRENT_PROVIDER"
    );

    if (localProvider === "Metamask") {
      connectWallet(injected, "Metamask");
    } else if (localProvider === "WalletConnect") {
      connectWallet(walletconnect, "WalletConnect");
    } else if (localProvider === "CoinBase") {
      connectWallet(CoinbaseWallet, "CoinBase");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectHandler = () => {
    if (!account) {
      handleOpen();
    } else if (account) {
      deactivate();
      window.localStorage.clear();
      window.location.reload();
    }
  };

  // useEffect(() => {
  //   const account = window.localStorage.getItem("account");
  //   if (account === "metamask") {
  //     loadWeb3();
  //   }
  //   // changeNetwork();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // async function loadWeb3() {
  //   if (window.ethereum) {
  //     window.localStorage.setItem("account", "metamask");
  //     window.web3 = new Web3(window.ethereum);
  //     try {
  //       loadBlockchainData();
  //       getCurrentAddressConnected();
  //       addAccountsAndChainListener();
  //       const accounts = await window.ethereum.request({
  //         method: "eth_requestAccounts",
  //       });
  //       // setAccount(accounts[0]);
  //       setShowLogin(false);
  //     } catch (error) {
  //       if (error.code === 4001) {
  //         setAccessAccountDenied(true);
  //       } else console.error(error);
  //     }
  //   } else {
  //     setShowLogin(false);
  //     setInstallEthereum(true);
  //   }
  // }

  // const loadBlockchainData = async () => {
  //   const contract = new window.web3.eth.Contract(contractAbi, contractAddress);
  //   setContract(contract);
  //   const tokenContract = new window.web3.eth.Contract(tokenAbi, tokenAddress);
  //   setTokenContract(tokenContract);
  //   // const chainId = await window.web3.eth.getChainId();
  //   // setChainId(chainId);
  //   console.log(contract, tokenContract);

  //   if (chainId === CONNECTABLE_CHAIN) {
  //     toast.success(`You are connected to main net`);

  //   } else {
  //     toast.error("Please connect to main net");
  //   }
  // };

  // const getCurrentAddressConnected = async () => {
  //   try {
  //     const accounts = await window.ethereum.request({
  //       method: "eth_accounts",
  //     });
  //     if (accounts.length > 0) {
  //       setAccount(accounts[0]);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const addAccountsAndChainListener = async () => {
  //   //this event will be emitted when the currently connected chain changes.
  //   window.ethereum.on("chainChanged", (_chainId) => {
  //     window.location.reload();
  //   });

  //   // this event will be emitted whenever the user's exposed account address changes.
  //   window.ethereum.on("accountsChanged", (accounts) => {
  //     window.location.reload();
  //   });
  // };

  // const changeNetwork = async () => {
  //   if (window.ethereum) {
  //     try {
  //       await window.ethereum.request({
  //         method: "wallet_switchEthereumChain",
  //         params: [
  //           {
  //             chainId: Web3.utils.toHex(CONNECTABLE_CHAINID),
  //           },
  //         ],
  //       });
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // };
  return (
    <div className="App">
      <BrowserRouter>
        <Toaster position="bottom-center" />
        <Layout
          showLogin={showLogin}
          setShowLogin={setShowLogin}
          account={account}
          totalSupply={totalSupply}
          displayPrice={displayPrice}
          loadWeb3={connectHandler}
          maxSupply={maxSupply}
          showLogout={showLogout}
          setShowLogout={setShowLogout}
          connectHandler={connectHandler}
        >
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  contract={contract}
                  tokenContract={tokenContract}
                  poolIds={poolIds}
                  account={account}
                  stackOpen={stackOpen}
                  setStackOpen={setStackOpen}
                  stackDetailOpen={stackDetailOpen}
                  setStackDetailOpen={setStackDetailOpen}
                  loadWeb3={connectHandler}
                />
              }
            />
            <Route
              path="rewards"
              element={
                <Reward
                  contract={contract}
                  tokenContract={tokenContract}
                  poolIds={poolIds}
                  account={account}
                  stackOpen={stackOpen}
                  setStackOpen={setStackOpen}
                  stackDetailOpen={stackDetailOpen}
                  setStackDetailOpen={setStackDetailOpen}
                />
              }
            />
            <Route path="leaderboard" element={<LeaderBoard />} />
            <Route path="swap" element={<Swap />} />
            <Route
              path="details/:id/:type"
              element={
                <Details
                  contract={contract}
                  tokenContract={tokenContract}
                  poolIds={poolIds}
                  account={account}
                />
              }
            />
            <Route
              path="stake"
              element={
                <Stake
                  contract={contract}
                  tokenContract={tokenContract}
                  poolIds={poolIds}
                  account={account}
                />
              }
            />
          </Routes>
        </Layout>

        {/* <Modal
        showLogin={showLogin}
        setShowLogin={setShowLogin}
        loadWeb3={loadWeb3}
      /> */}
        <WalletModal
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          handleOpen={handleOpen}
          connect={connectWallet}
        />
        <LogoutModal open={showLogout} setOpen={setShowLogout} />
        <StackModal
          open={stackOpen}
          setOpen={setStackOpen}
          stackDetailOpen={stackDetailOpen}
          setStackDetailOpen={setStackDetailOpen}
        />
        <StackDetailModal open={stackDetailOpen} setOpen={setStackDetailOpen} />
        <InformationModal
          open={accessAccountDenied}
          setOpen={setAccessAccountDenied}
        />
        <InformationModal
          open={installEthereum}
          setOpen={setInstallEthereum}
          title="Oops"
          desc="Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!"
        />
      </BrowserRouter>
    </div>
  );
}

export default App;
