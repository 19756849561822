export const contractAddress = "0xEeF6D36406433f1CFAa2E92B3619CE7dca758CCD";

export const contractAbi = [
  { inputs: [], stateMutability: "nonpayable", type: "constructor" },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_duration", type: "uint256" },
      { internalType: "uint256", name: "_multiplier", type: "uint256" },
      { internalType: "uint256", name: "_apy", type: "uint256" },
      { internalType: "uint256", name: "_withdrawalFee", type: "uint256" },
      { internalType: "uint256", name: "_unstakePenalty", type: "uint256" },
    ],
    name: "addStakePool",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_stakeId", type: "uint256" }],
    name: "claimRewards",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "dynamicApy",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "emergencyUnstake",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getAllPoolDetails",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "id", type: "uint256" },
          { internalType: "uint256", name: "duration", type: "uint256" },
          { internalType: "uint256", name: "multiplier", type: "uint256" },
          { internalType: "uint256", name: "APY", type: "uint256" },
          { internalType: "uint256", name: "withdrawalFee", type: "uint256" },
          { internalType: "uint256", name: "unstakePenalty", type: "uint256" },
          { internalType: "uint256", name: "stakedTokens", type: "uint256" },
          { internalType: "uint256", name: "claimedRewards", type: "uint256" },
          { internalType: "uint256", name: "status", type: "uint256" },
          { internalType: "address", name: "creator", type: "address" },
          { internalType: "uint256", name: "createdTime", type: "uint256" },
        ],
        internalType: "struct MikawaStake.stakePool[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_stakePoolId", type: "uint256" },
    ],
    name: "getDPR",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_stakeId", type: "uint256" }],
    name: "getElapsedTime",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_stakePoolId", type: "uint256" },
    ],
    name: "getFixedAPY",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_stakePoolId", type: "uint256" },
    ],
    name: "getStakePoolDetailsById",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "id", type: "uint256" },
          { internalType: "uint256", name: "duration", type: "uint256" },
          { internalType: "uint256", name: "multiplier", type: "uint256" },
          { internalType: "uint256", name: "APY", type: "uint256" },
          { internalType: "uint256", name: "withdrawalFee", type: "uint256" },
          { internalType: "uint256", name: "unstakePenalty", type: "uint256" },
          { internalType: "uint256", name: "stakedTokens", type: "uint256" },
          { internalType: "uint256", name: "claimedRewards", type: "uint256" },
          { internalType: "uint256", name: "status", type: "uint256" },
          { internalType: "address", name: "creator", type: "address" },
          { internalType: "uint256", name: "createdTime", type: "uint256" },
        ],
        internalType: "struct MikawaStake.stakePool",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_stakeId", type: "uint256" }],
    name: "getStakePoolIdByStakeId",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getTotalUnclaimedRewards",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_userAddress", type: "address" },
    ],
    name: "getTotalUnclaimedRewardsByAddress",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_stakeId", type: "uint256" }],
    name: "getUnclaimedRewards",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getUserAllStakeDetails",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "id", type: "uint256" },
          { internalType: "uint256", name: "stakePoolId", type: "uint256" },
          { internalType: "uint256", name: "stakeBalance", type: "uint256" },
          {
            internalType: "uint256",
            name: "totalClaimedRewards",
            type: "uint256",
          },
          { internalType: "uint256", name: "lastClaimedTime", type: "uint256" },
          { internalType: "address", name: "tokenAddress", type: "address" },
          { internalType: "uint256", name: "status", type: "uint256" },
          { internalType: "address", name: "owner", type: "address" },
          { internalType: "uint256", name: "createdTime", type: "uint256" },
          { internalType: "uint256", name: "unlockTime", type: "uint256" },
          { internalType: "uint256", name: "lockDuration", type: "uint256" },
        ],
        internalType: "struct MikawaStake.userStake[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_userAddress", type: "address" },
    ],
    name: "getUserAllStakeDetailsByAddress",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "id", type: "uint256" },
          { internalType: "uint256", name: "stakePoolId", type: "uint256" },
          { internalType: "uint256", name: "stakeBalance", type: "uint256" },
          {
            internalType: "uint256",
            name: "totalClaimedRewards",
            type: "uint256",
          },
          { internalType: "uint256", name: "lastClaimedTime", type: "uint256" },
          { internalType: "address", name: "tokenAddress", type: "address" },
          { internalType: "uint256", name: "status", type: "uint256" },
          { internalType: "address", name: "owner", type: "address" },
          { internalType: "uint256", name: "createdTime", type: "uint256" },
          { internalType: "uint256", name: "unlockTime", type: "uint256" },
          { internalType: "uint256", name: "lockDuration", type: "uint256" },
        ],
        internalType: "struct MikawaStake.userStake[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getUserDetails",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "id", type: "uint256" },
          {
            internalType: "uint256",
            name: "totalStakedBalance",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalClaimedRewards",
            type: "uint256",
          },
          { internalType: "uint256", name: "createdTime", type: "uint256" },
        ],
        internalType: "struct MikawaStake.user",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_userAddress", type: "address" },
    ],
    name: "getUserDetailsByAddress",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "id", type: "uint256" },
          {
            internalType: "uint256",
            name: "totalStakedBalance",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalClaimedRewards",
            type: "uint256",
          },
          { internalType: "uint256", name: "createdTime", type: "uint256" },
        ],
        internalType: "struct MikawaStake.user",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_stakeId", type: "uint256" }],
    name: "getUserStakeBalance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_stakeId", type: "uint256" }],
    name: "getUserStakeDetailsByStakeId",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "id", type: "uint256" },
          { internalType: "uint256", name: "stakePoolId", type: "uint256" },
          { internalType: "uint256", name: "stakeBalance", type: "uint256" },
          {
            internalType: "uint256",
            name: "totalClaimedRewards",
            type: "uint256",
          },
          { internalType: "uint256", name: "lastClaimedTime", type: "uint256" },
          { internalType: "address", name: "tokenAddress", type: "address" },
          { internalType: "uint256", name: "status", type: "uint256" },
          { internalType: "address", name: "owner", type: "address" },
          { internalType: "uint256", name: "createdTime", type: "uint256" },
          { internalType: "uint256", name: "unlockTime", type: "uint256" },
          { internalType: "uint256", name: "lockDuration", type: "uint256" },
        ],
        internalType: "struct MikawaStake.userStake",
        name: "",
        type: "tuple",
      },
      {
        components: [
          { internalType: "uint256", name: "id", type: "uint256" },
          { internalType: "uint256", name: "duration", type: "uint256" },
          { internalType: "uint256", name: "multiplier", type: "uint256" },
          { internalType: "uint256", name: "APY", type: "uint256" },
          { internalType: "uint256", name: "withdrawalFee", type: "uint256" },
          { internalType: "uint256", name: "unstakePenalty", type: "uint256" },
          { internalType: "uint256", name: "stakedTokens", type: "uint256" },
          { internalType: "uint256", name: "claimedRewards", type: "uint256" },
          { internalType: "uint256", name: "status", type: "uint256" },
          { internalType: "address", name: "creator", type: "address" },
          { internalType: "uint256", name: "createdTime", type: "uint256" },
        ],
        internalType: "struct MikawaStake.stakePool",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getUserStakeIds",
    outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_userAddress", type: "address" },
    ],
    name: "getUserStakeIdsByAddress",
    outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_stakeId", type: "uint256" }],
    name: "getUserStakeOwner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "isPaused",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_stakeId", type: "uint256" }],
    name: "isStakeLocked",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "magnitude",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pauseStake",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "poolIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_stakePoolId", type: "uint256" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    name: "stake",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "stakeIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "stakePoolArray",
    outputs: [
      { internalType: "uint256", name: "id", type: "uint256" },
      { internalType: "uint256", name: "duration", type: "uint256" },
      { internalType: "uint256", name: "multiplier", type: "uint256" },
      { internalType: "uint256", name: "APY", type: "uint256" },
      { internalType: "uint256", name: "withdrawalFee", type: "uint256" },
      { internalType: "uint256", name: "unstakePenalty", type: "uint256" },
      { internalType: "uint256", name: "stakedTokens", type: "uint256" },
      { internalType: "uint256", name: "claimedRewards", type: "uint256" },
      { internalType: "uint256", name: "status", type: "uint256" },
      { internalType: "address", name: "creator", type: "address" },
      { internalType: "uint256", name: "createdTime", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "stakePoolsById",
    outputs: [
      { internalType: "uint256", name: "id", type: "uint256" },
      { internalType: "uint256", name: "duration", type: "uint256" },
      { internalType: "uint256", name: "multiplier", type: "uint256" },
      { internalType: "uint256", name: "APY", type: "uint256" },
      { internalType: "uint256", name: "withdrawalFee", type: "uint256" },
      { internalType: "uint256", name: "unstakePenalty", type: "uint256" },
      { internalType: "uint256", name: "stakedTokens", type: "uint256" },
      { internalType: "uint256", name: "claimedRewards", type: "uint256" },
      { internalType: "uint256", name: "status", type: "uint256" },
      { internalType: "address", name: "creator", type: "address" },
      { internalType: "uint256", name: "createdTime", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalClaimedBalance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalStakedBalance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "unpauseStake",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_stakeId", type: "uint256" }],
    name: "unstake",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "unstakePenalty",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "userIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "userStakeArray",
    outputs: [
      { internalType: "uint256", name: "id", type: "uint256" },
      { internalType: "uint256", name: "stakePoolId", type: "uint256" },
      { internalType: "uint256", name: "stakeBalance", type: "uint256" },
      { internalType: "uint256", name: "totalClaimedRewards", type: "uint256" },
      { internalType: "uint256", name: "lastClaimedTime", type: "uint256" },
      { internalType: "address", name: "tokenAddress", type: "address" },
      { internalType: "uint256", name: "status", type: "uint256" },
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "uint256", name: "createdTime", type: "uint256" },
      { internalType: "uint256", name: "unlockTime", type: "uint256" },
      { internalType: "uint256", name: "lockDuration", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "uint256", name: "", type: "uint256" },
    ],
    name: "userStakeIds",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "userStakesById",
    outputs: [
      { internalType: "uint256", name: "id", type: "uint256" },
      { internalType: "uint256", name: "stakePoolId", type: "uint256" },
      { internalType: "uint256", name: "stakeBalance", type: "uint256" },
      { internalType: "uint256", name: "totalClaimedRewards", type: "uint256" },
      { internalType: "uint256", name: "lastClaimedTime", type: "uint256" },
      { internalType: "address", name: "tokenAddress", type: "address" },
      { internalType: "uint256", name: "status", type: "uint256" },
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "uint256", name: "createdTime", type: "uint256" },
      { internalType: "uint256", name: "unlockTime", type: "uint256" },
      { internalType: "uint256", name: "lockDuration", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "withdrawContractETH",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "withdrawContractRewardTokens",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  { stateMutability: "payable", type: "receive" },
];
